jQuery(document).ready(function() {

  if (jQuery('.header-sticky').length >= 1) {
    jQuery(window).scroll(function() {
      var header = jQuery(document).scrollTop();
      var headerHeight = jQuery('.header-sticky').height();
      if (header > headerHeight) {
        jQuery('.header-sticky').addClass('sticky');
      } else {
        jQuery('.header-sticky').removeClass('sticky');
      }
    })
  }

  // init Isotope for grid layout
  var grid = jQuery('#filter-grid');

  grid.isotope({
    itemSelector: '.js-grid-item',
    layoutMode: 'fitRows'
  });

  grid.imagesLoaded().progress( function() {
    grid.isotope('layout');
  });

  // filter grid items on button click
  jQuery('#filter-list').on( 'click', 'button', function() {
    var filterValue = jQuery(this).attr('data-filter');
    grid.isotope({ filter: filterValue });
  });

});